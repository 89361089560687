import { Button } from '@80lines/toolkit'
import Card from 'components/atoms/card'
import Column from 'components/atoms/column'
import Form from 'components/atoms/form'
import Text from 'components/atoms/text'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm, { validations } from 'hooks/use-form'
import React from 'react'
import isValidEmail from 'validations/isValidEmail'

export interface LoginFormProps {
  onForgotPassword?: (event: { email?: string }) => void
  onSubmit?: (event: { email: string; password: string }) => void
  pending?: boolean
  className?: string
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  pending,
  onForgotPassword,
  className,
}) => {
  const { getValue, setValue, getError, submit } = useForm<{
    email: string
    password: string
  }>({
    onSubmit,
    validations: {
      email: [validations.required, isValidEmail],
      password: [validations.required],
    },
  })

  const performForgotPassword = () => {
    const email = getValue('email')
    onForgotPassword?.({ email })
  }

  return (
    <Card
      className={classNameBuilder(
        'w-full max-w-[35rem] px-md pt-10 pb-8 md:px-xl lg:px-16',
        className
      )}
    >
      <Form onSubmit={submit}>
        <Column className="space-y-md">
          <Text
            color="indigo-500"
            fontWeight="bold"
            className="w-full text-center uppercase"
          >
            Login de Acesso
          </Text>
          <TextInput
            type="email"
            label="E-mail"
            placeholder="usuario@dominio.com"
            value={getValue('email')}
            error={getError('email')}
            onChange={value => setValue('email', value)}
            required
          />
          <TextInput
            label="Senha"
            placeholder="Insira sua senha"
            type="password"
            value={getValue('password')}
            error={getError('password')}
            onChange={value => setValue('password', value)}
            required
          />
        </Column>
        <Button
          type="submit"
          shape="round"
          pending={pending}
          colorSchema="accent"
          className="mt-lg w-full"
        >
          Acessar
        </Button>
        <Column className="mt-lg w-full items-center border-t border-gray-300 pt-md">
          <a
            className="cursor-pointer text-sm text-indigo-400 hover:text-blue-400"
            onClick={performForgotPassword}
          >
            Esqueci minha senha
          </a>
        </Column>
      </Form>
    </Card>
  )
}

export default LoginForm
