import { Button } from '@80lines/toolkit'
import DoneIcon from 'assets/svg/done.svg'
import Card from 'components/atoms/card'
import Row from 'components/atoms/row'
import Separator from 'components/atoms/separator'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export interface ResetPasswordSucceedProps {
  className?: string
  onCancel?: () => void
}
const ResetPasswordSucceed: React.FC<ResetPasswordSucceedProps> = ({
  className,
  onCancel,
}) => {
  return (
    <Card
      className={classNameBuilder(
        'relative px-lg py-xl sm:px-16 sm:py-12',
        className
      )}
      style={{
        minWidth: '20rem',
        width: '100%',
        maxWidth: '35rem',
      }}
    >
      <Row className="mx-auto mb-md h-[4.5rem] w-[4.5rem] items-center justify-center rounded-full bg-green-400 sm:mb-xl">
        <DoneIcon name="success" className="h-xl w-xl text-white" />
      </Row>
      <Row className="items-center justify-center">
        <Text
          color="indigo-500"
          fontWeight="bold"
          className="w-full text-center uppercase"
        >
          E-mail enviado com sucesso!
        </Text>
      </Row>
      <Separator />
      <Row>
        <Text size="sm" className="text-center">
          Verifique o seu e-mail para continuar com a recuperação.
        </Text>
      </Row>
      <Separator size="xl" />
      <Button colorSchema="accent" className="w-full" onClick={onCancel}>
        Voltar para o login
      </Button>
    </Card>
  )
}

export default ResetPasswordSucceed
