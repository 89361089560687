import { useMutation } from '@tanstack/react-query'
import changePasswordService, {
  ParamsType,
  ResponseType,
} from 'services/authentication/change-password-service'
import { MutateHookType } from 'types/mutate-hook-type'

type HookType = MutateHookType<ParamsType, ResponseType>

export const useChangePassword: HookType = options => {
  const mutation = useMutation(changePasswordService, options)
  const { data, error, isLoading, mutate: fetch } = mutation
  return { data, error, isLoading, fetch }
}
