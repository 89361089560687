import CookieKeys from 'constants/cookie-keys'
import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

export type ParamsType = {
  email: string
  password: string
  newPassword: string
}
export type ResponseType = SuccessType<{ accessToken: string }>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const changePasswordService: ServiceType = async params => {
  try {
    const { email, password, newPassword } = params
    const path = '/students/auth/change-password'
    const response = await api.post(path, null, {
      auth: { username: email, password: `${password}:${newPassword}` },
    })

    const accessToken = response?.data?.accessToken || ''
    localStorage.setItem(CookieKeys.ACCESS_TOKEN, accessToken)
    return response.data
  } catch (error) {
    const defaultMessage = 'Erro ao alterar senha'
    throw handleError({ error, defaultMessage, payload: params })
  }
}

export default changePasswordService
