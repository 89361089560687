import React from 'react'
import { useStateController } from './state-controller-context'

export interface StateProps {
  id: string
  children: React.ReactElement
}
export const State: React.FC<StateProps> = ({ id, children }) => {
  const { currentState } = useStateController()
  return currentState === id ? children : null
}

export default State
