import { Button } from '@80lines/toolkit'
import Card from 'components/atoms/card'
import Column from 'components/atoms/column'
import Form from 'components/atoms/form'
import Text from 'components/atoms/text'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm, { validations } from 'hooks/use-form'
import React from 'react'
import isValidEmail from 'validations/isValidEmail'

export interface ChangePasswordFormProps {
  className?: string
  email: string
  password: string
  onSubmit?: (event: {
    email: string
    password: string
    newPassword: string
  }) => void
  pending?: boolean
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  className,
  email,
  password,
  onSubmit,
  pending,
}) => {
  const { getValue, setValue, getError, submit } = useForm<{
    email: string
    password: string
    newPassword: string
    newPassword2: string
  }>({
    onSubmit,
    initialValues: { email, password },
    validations: {
      email: [validations.required, isValidEmail],
      password: [validations.required],
      newPassword: [validations.required],
      newPassword2: [
        validations.required,
        value =>
          value === getValue('newPassword') ? undefined : 'Senhas não conferem',
      ],
    },
  })

  return (
    <Card
      className={classNameBuilder(
        'w-full max-w-[35rem] px-md pt-10 pb-8 md:px-xl lg:px-16',
        className
      )}
    >
      <Form onSubmit={submit}>
        <Column className="space-y-lg">
          <Text
            color="indigo-500"
            fontWeight="bold"
            className="w-full text-center uppercase"
          >
            Alterar Senha
          </Text>
          <TextInput
            type="email"
            label="E-mail"
            placeholder="usuario@dominio.com"
            value={getValue('email')}
            error={getError('email')}
            onChange={value => setValue('email', value)}
            disabled
          />
          <TextInput
            label="Senha"
            placeholder="Insira sua senha"
            type="password"
            value={getValue('newPassword')}
            error={getError('newPassword')}
            onChange={value => setValue('newPassword', value)}
            required
          />
          <TextInput
            label="Confirme a senha"
            placeholder="Confirme sua senha"
            type="password"
            value={getValue('newPassword2')}
            error={getError('newPassword2')}
            onChange={value => setValue('newPassword2', value)}
            required
          />
        </Column>
        <Button
          type="submit"
          shape="round"
          pending={pending}
          colorSchema="accent"
          className="mt-xl w-full"
        >
          Alterar Senha
        </Button>
      </Form>
    </Card>
  )
}

export default ChangePasswordForm
