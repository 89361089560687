import { State, StateController } from '@80lines/toolkit/state-controller'
import { useChangePassword } from 'api-hooks/authentication/use-change-password'
import useLogin from 'api-hooks/authentication/use-login'
import { useResetPassword } from 'api-hooks/authentication/use-reset-password'
import Column from 'components/atoms/column'
import Logo from 'components/atoms/logo'
import Webpage from 'components/organisms/webpage'
import { classNameBuilder } from 'helpers/class-name-builder'
import { useToast } from 'hooks/use-toast'
import React, { useState } from 'react'
import ChangePasswordForm from './change-password-form'
import ForgotPasswordForm from './forgot-password-form'
import LoginForm from './login-form'
import ResetPasswordSucceed from './reset-password-succeed'

type LoginScreenState =
  | 'login'
  | 'forgot-password'
  | 'change-password'
  | 'reset-password-succeed'
export interface LoginScreenProps {}

const LoginScreen: React.FC<LoginScreenProps> = () => {
  const [screenState, setScreenState] = useState<LoginScreenState>('login')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const { showError } = useToast()

  const reloadWindow = () => window.location.reload()

  const { fetch: login, isLoading } = useLogin({
    onSuccess: reloadWindow,
    onError: ({ error, message, payload }) => {
      if (error === 'required_change_password') {
        const email = payload?.email as string
        const password = payload?.password as string
        setEmail(email)
        setPassword(password)
        setScreenState('change-password')
      } else {
        showError({ message })
      }
    },
  })

  const { fetch: resetPassword, isLoading: sendingEmail } = useResetPassword({
    onError: showError,
    onSuccess: () => setScreenState('reset-password-succeed'),
  })

  const { fetch: changePassword, isLoading: changingPassword } =
    useChangePassword({ onError: showError, onSuccess: reloadWindow })

  const setForgotPassword = (event: { email: string }) => {
    setEmail(event.email)
    setScreenState('forgot-password')
  }

  const showLoginForm = () => setScreenState('login')

  return (
    <Webpage title="Autenticação | Superus" className="flex flex-1 flex-col">
      <Column
        className={classNameBuilder(
          'relative h-screen flex-1 bg-indigo-50',
          'before:div top-0 before:absolute before:inset-x-0 before:min-h-[16rem] before:bg-indigo-600 sm:before:min-h-[25rem]'
        )}
      >
        <Column className="z-0 items-center px-sm">
          <Logo className="mt-10 h-[3.15rem] w-[12rem] sm:mt-20 sm:h-[4.5rem] sm:w-[17rem]" />
          <StateController currentState={screenState}>
            <State id="login">
              <LoginForm
                className="mt-6 sm:mt-14"
                onSubmit={login}
                pending={isLoading}
                onForgotPassword={setForgotPassword}
              />
            </State>
            <State id="change-password">
              <ChangePasswordForm
                className="mt-6 sm:mt-14"
                email={email}
                password={password}
                onSubmit={changePassword}
                pending={changingPassword}
              />
            </State>
            <State id="reset-password-succeed">
              <ResetPasswordSucceed
                className="mt-6 sm:mt-14"
                onCancel={showLoginForm}
              />
            </State>
            <State id="forgot-password">
              <ForgotPasswordForm
                className="mt-6 sm:mt-14"
                initialValues={{ email }}
                onCancel={showLoginForm}
                onSubmit={resetPassword}
                pending={sendingEmail}
              />
            </State>
          </StateController>
        </Column>
      </Column>
    </Webpage>
  )
}

export default LoginScreen
