import React from 'react'

interface FormProps {
  className?: string
  onSubmit?: () => void
}
const Form: React.FC<FormProps> = ({ className, onSubmit, children }) => {
  return (
    <form
      className={className ?? 'flex flex-col'}
      onSubmit={event => {
        event.preventDefault()
        onSubmit?.()
      }}
    >
      {children}
    </form>
  )
}

export default Form
