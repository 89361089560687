import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

export type ParamsType = { email: string }
export type ResponseType = SuccessType<any>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const resetPasswordService: ServiceType = async ({ email }) => {
  try {
    await api.post('/students/auth/forgot-password', { email })
    return {
      message:
        'Siga as instruções enviadas para seu e-mail para redefinir a senha',
    }
  } catch (error) {
    const defaultMessage = 'Erro ao redefinir senha'
    throw handleError({ error, defaultMessage })
  }
}

export default resetPasswordService
