import React from 'react'

export interface BoxProps extends React.HTMLAttributes<HTMLDivElement> {}

const Box: React.FC<BoxProps> = React.forwardRef<HTMLInputElement, BoxProps>(
  ({ className = 'flex', children, ...props }, ref) => {
    return (
      <div ref={ref} className={className} {...props}>
        {children}
      </div>
    )
  }
)

export default Box
