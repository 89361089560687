import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

type InputType = 'text' | 'password' | 'email'
export interface InputProps {
  type?: InputType
  className?: string
  placeholder?: string
  value?: string
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  error?: boolean
}

const Input: React.FC<InputProps> = ({
  className,
  onChange,
  disabled,
  error,
  ...props
}) => {
  return (
    <input
      className={classNameBuilder(
        className ??
          'form-input block w-full rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm',
        error ? 'border-red-400' : 'border-gray-300',
        disabled ? 'text-gray-500' : 'text-gray-900'
      )}
      onChange={event => onChange?.(event.target.value, event)}
      disabled={disabled}
      {...props}
    />
  )
}

export default Input
