import React from 'react'

type OmittedProps = 'viewBox' | 'fill' | 'xmlns'
export interface LogoProps
  extends Omit<React.SVGProps<SVGSVGElement>, OmittedProps> {}

const Logo: React.FC<LogoProps> = ({ ...props }) => {
  return (
    <svg
      viewBox="0 0 416 76"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M354.9 0H376V30.7C376 30.7 354.7 42.1 343.1 31.7V11.8C343.1 5.3 348.4 0 354.9 0Z"
        fill="white"
      />
      <path
        d="M404.2 0H383.1V30.7C383.1 30.7 404.4 42.1 416 31.7V11.8C416 5.3 410.7 0 404.2 0Z"
        fill="white"
      />
      <path
        d="M404.2 74.8H383.1V38.7C383.1 38.7 400.8 47.4 416 35.2V63C416 69.5 410.7 74.8 404.2 74.8Z"
        fill="white"
      />
      <path
        d="M354.9 74.8H376V38.7C376 38.7 358.3 47.4 343.1 35.2V63C343.1 69.5 348.4 74.8 354.9 74.8Z"
        fill="white"
      />
      <path
        d="M41.7 14.1C38.7 15.9 34.9 15.5 32.2 13.2C29.9 11.4 27.6 10.6 24.4 10.6C17.7 10.6 14.2 14.6 14.2 19.2C14.2 21.4 15 25.1 22 27.9L29.2 30.8C42.5 36.2 46.2 43.8 46.2 52C46.2 65.7 36.5 75.1 23 75.1C14.7 75.1 9.7 72 6 67.9C2.1 63.6 0.4 58.8 0 53.9L0.4 53.8C5.4 52.7 10.4 55.1 13.1 59.5C13.4 59.9 13.6 60.3 13.9 60.7C16 63.3 19 65 23.3 65C30 65 35.2 60.1 35.2 52.9C35.2 45.6 29.6 42.5 24.8 40.6L17.9 37.7C12 35.3 3.2 30.3 3.2 19.5C3.2 9.8 10.8 0.5 24.3 0.5C32.1 0.5 36.5 3.4 38.8 5.4C40.7 7.1 42.8 9.6 44.3 12.6L41.7 14.1Z"
        fill="white"
      />
      <path
        d="M51.5 19.5C56.1 19.5 59.9 23.2 59.9 27.9V53.6C59.9 57.7 61.3 60.3 62.5 61.7C64 63.6 66.9 65.4 71.1 65.4C75.3 65.4 78.2 63.5 79.7 61.7C80.9 60.3 82.3 57.8 82.3 53.6V27.9C82.3 23.3 86 19.5 90.7 19.5H92.9V52.7C92.9 57.8 92.1 63 88.8 67.2C84.9 72.4 78.1 75 71.1 75C64.1 75 57.3 72.3 53.4 67.2C50.1 63.1 49.3 57.8 49.3 52.7V19.5H51.5Z"
        fill="white"
      />
      <path
        d="M114 19.5C120.1 19.5 124.3 20.2 128.3 23.6C132.7 27.5 133.8 32.8 133.8 36.7C133.8 38.8 133.5 44.7 129.3 48.8C126.3 51.8 121.8 53.6 115.5 53.6H108.7V65.6C108.7 70.1 105.1 73.7 100.6 73.7H98.2V19.5H114ZM108.8 41.6C108.8 43.3 110.1 44.6 111.8 44.6H113.1C115.1 44.6 117.7 44.6 120 42.9C122 41.4 122.9 38.9 122.9 36.6C122.9 33.6 121.4 31.6 119.9 30.4C117.7 28.9 114.9 28.7 112.4 28.7H111.7C110 28.7 108.7 30 108.7 31.7V41.6H108.8Z"
        fill="white"
      />
      <path
        d="M152.9 19.5C160.4 19.5 164.2 21.5 166.7 23.6C170.8 27.1 172.1 31.7 172.1 35.8C172.1 41.1 170 45.7 165.8 48.6C164.4 49.6 162.5 50.5 159.9 51L176.8 73.9H171.7C166.7 73.9 162.4 70 159.6 65.8L149.9 53.2C149.5 52.5 148.7 52.1 147.9 52.1V73.9H137.3V19.5H152.9ZM147.8 41.5C147.8 43.2 149.1 44.5 150.8 44.5C152.8 44.5 161.2 44.3 161.2 36.3C161.2 28.2 152.9 28.2 150.9 28.2H150.7C149 28.2 147.7 29.5 147.7 31.2V41.5H147.8Z"
        fill="white"
      />
      <path
        d="M201.6 28.7H189V40.8H202.8V49.9H189V64.7H201.6C205.4 64.7 208.5 67.8 208.5 71.6V73.8H178.4V19.5H208.5V21.7C208.4 25.6 205.4 28.7 201.6 28.7Z"
        fill="white"
      />
      <path
        d="M220.7 19.5H225.5C227.2 19.5 228.8 20.5 229.5 22.1L242.8 54L256.7 22.1C257.4 20.5 258.9 19.5 260.6 19.5H265.4L274.5 73.8H263.8L259 40.4L244.4 73.8H240.8L226.9 40.4L221.4 73.8H210.7L220.7 19.5Z"
        fill="white"
      />
      <path
        d="M331.3 46.7C331.3 63.2 319.4 75 302.3 75C285.2 75 273.3 63.2 273.3 46.7C273.3 30.2 285.2 18.3 302.3 18.3C319.5 18.3 331.3 30.2 331.3 46.7ZM320.4 46.7C320.4 35.7 312.9 27.9 302.3 27.9C291.7 27.9 284.2 35.7 284.2 46.7C284.2 57.7 291.7 65.4 302.3 65.4C313 65.4 320.4 57.7 320.4 46.7Z"
        fill="white"
      />
    </svg>
  )
}

export default Logo
