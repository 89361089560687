import Box from 'components/atoms/box'
import Head from 'next/head'
import React from 'react'

export interface WebpageProps {
  title?: string
  className?: string
}

const Webpage: React.FC<WebpageProps> = ({
  title = 'Superus',
  className,
  children,
}) => {
  return (
    <>
      <Head>
        <title>{title}</title>
        <link rel="icon" href="/favicon.svg" />
      </Head>
      <Box
        className={
          className ??
          'flex min-h-screen min-w-full flex-1 flex-row overflow-hidden'
        }
      >
        {children}
      </Box>
    </>
  )
}

export default Webpage
