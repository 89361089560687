import handleError from 'helpers/error-handler-helper'
import api from 'libs/api'
import { SuccessType } from 'types/success-type'

export type ParamsType = { email: string; password: string }
export type ResponseType = SuccessType<{ accessToken: string }>
type ServiceType = (params: ParamsType) => Promise<ResponseType>

const loginService: ServiceType = async params => {
  try {
    const { email: username, password } = params
    const path = '/students/auth/login'
    const { data } = await api.post(path, null, {
      auth: { username, password },
    })
    return data
  } catch (error) {
    const defaultMessage = 'Erro ao efetuar login'
    throw handleError({ error, defaultMessage, payload: params })
  }
}

export default loginService
