import { Button } from '@80lines/toolkit'
import ArrowBackIcon from 'assets/svg/arrow-back.svg'
import Box from 'components/atoms/box'
import Card from 'components/atoms/card'
import Form from 'components/atoms/form'
import Text from 'components/atoms/text'
import TextInput from 'components/molecules/text-input'
import { classNameBuilder } from 'helpers/class-name-builder'
import useForm, { validations } from 'hooks/use-form'
import React from 'react'
import isValidEmail from 'validations/isValidEmail'

export interface ForgotPasswordFormProps {
  className?: string
  initialValues?: { email?: string }
  onSubmit?: (event: { email: string }) => void
  pending?: boolean
  onCancel?: () => void
}

const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({
  className,
  initialValues,
  onSubmit,
  pending,
  onCancel,
}) => {
  const { getValue, setValue, getError, submit } = useForm<{ email: string }>({
    onSubmit,
    initialValues,
    validations: {
      email: [validations.required, isValidEmail],
    },
  })

  return (
    <Card
      className={classNameBuilder('relative', className)}
      style={{
        padding: '3rem 4rem',
        minWidth: '20rem',
        width: '100%',
        maxWidth: '35rem',
      }}
    >
      <Box className="absolute top-0 left-0 p-md">
        <Button shape="round" size="xs" className="h-11" onClick={onCancel}>
          <ArrowBackIcon className="h-md w-md" />
        </Button>
      </Box>
      <Form className="flex flex-col space-y-xl" onSubmit={submit}>
        <Text
          color="indigo-500"
          fontWeight="bold"
          className="w-full text-center uppercase"
        >
          Recuperar Senha
        </Text>
        <Text size="sm" className="w-full text-center">
          Enviaremos um link para seu e-mail cadastrado
        </Text>
        <TextInput
          placeholder="Informe seu e-mail *"
          value={getValue('email')}
          error={getError('email')}
          onChange={value => setValue('email', value)}
        />
        <Button
          type="submit"
          colorSchema="accent"
          className="w-full"
          pending={pending}
        >
          Enviar
        </Button>
      </Form>
    </Card>
  )
}

export default ForgotPasswordForm
