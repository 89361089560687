import Box, { BoxProps } from 'components/atoms/box'
import React from 'react'

export interface CardProps extends BoxProps {}
const Card: React.FC<CardProps> = ({ children, className = '', ...rest }) => {
  return (
    <Box
      {...rest}
      className={`overflow-hidden rounded-lg bg-white shadow-md ${className}`}
    >
      {children}
    </Box>
  )
}

export default Card
