import React from 'react'

export type StateControllerContextType<T = unknown> = {
  currentState?: T
  // onChange?: (stateId: T) => void
}

export const StateControllerContext =
  React.createContext<StateControllerContextType>(null)

export const useStateController = () => {
  const context = React.useContext<StateControllerContextType>(
    StateControllerContext
  )
  if (!context) {
    console.error(
      'Use the StateControllerContext inside a StateController component'
    )
  }
  return context
}
