import Input from 'components/atoms/input'
import Text from 'components/atoms/text'
import { classNameBuilder } from 'helpers/class-name-builder'
import React from 'react'

export type TextInputType = 'text' | 'password' | 'email'
export interface TextInputProps {
  placeholder?: string
  className?: string
  value?: string
  id?: string
  name?: string
  label?: string
  type?: TextInputType
  onChange?: (value: string, event: React.ChangeEvent<HTMLInputElement>) => void
  error?: string
  description?: React.ReactNode
  required?: boolean
  disabled?: boolean
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  label,
  description,
  required,
  error,
  ...props
}) => {
  return (
    <div>
      <div className="flex justify-between">
        <label
          htmlFor={id}
          className={classNameBuilder(
            'block text-sm text-gray-700',
            'after:ml-0.5 after:text-red-300 after:content-["*"]',
            !required && 'after:hidden'
          )}
        >
          {label}
        </label>
        {description}
      </div>
      <div className="mt-1">
        <Input {...props} error={!!error} />
      </div>
      <div className="-mt-1 h-0">
        <Text className="text-2xs text-red-400">{error}</Text>
      </div>
    </div>
  )
}

export default TextInput
