import React from 'react'
import { StateControllerContext } from './state-controller-context'

export interface StateControllerProps<T = string> {
  currentState: T
  children?: React.ReactElement[]
}
export const StateController = <T,>(
  props: React.PropsWithChildren<StateControllerProps<T>>
) => {
  return (
    <StateControllerContext.Provider value={props}>
      {props.children}
    </StateControllerContext.Provider>
  )
}
