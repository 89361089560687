import axios from 'axios'

const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
})

api.interceptors.request.use(config => {
  config.withCredentials = true
  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.data?.error?.includes('invalid_token')) {
      const newWindow = window as any
      if (
        typeof newWindow !== 'undefined' &&
        typeof newWindow.ReactNativeWebView !== 'undefined'
      ) {
        newWindow.ReactNativeWebView.postMessage('invalid_token')
      }
    }
    return Promise.reject(error?.response?.data)
  }
)

export default api
