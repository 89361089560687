import React from 'react'
import { Size } from 'types/size'

export interface SeparatorProps {
  size?: Size
}

const Separator: React.FC<SeparatorProps> = props => {
  const { size = 'sm', children } = props
  const sizeClass = sizeClassMap[size]
  return <div className={`flex shrink-0 ${sizeClass}`}>{children}</div>
}

export default Separator

const sizeClassMap: Record<Size, string> = {
  xs: 'w-xs h-xs',
  sm: 'w-sm h-sm',
  md: 'w-md h-md',
  lg: 'w-lg h-lg',
  xl: 'w-xl h-xl',
}
